body{
    /* background-color: var(--background-gray); */
    font-family: montserrat;
    background-color: black;
}


*{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

button{
    cursor: pointer;
}

:root{
    --background-gray : #151515;
    /* --page-blue: #1e82ff; */
    --background-green: linear-gradient(122deg, #90d229, #30ad37);
    /* font-size: clamp(); */
    /* font-size: 2.2vh; */
    /* height: 500vh; */
    z-index: 1;
    --border-thin-white: #6a6a6a;
    /* max-height: 100vh;
    overflow: hidden; */
    -webkit-text-size-adjust: 100%;
    --text-white: #d5d5d5;
    --subtitle-gray: #6b6b6b;

}

nav{
    position: fixed;
    top:0;
    z-index: 100 !important;
}



.link:hover{
    color: #30ad37 !important;
    transition: color .8s;
}

a{
    color:gray;
    text-decoration: none;
    background-image: var(--background-green);
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
    transition: color .8s;
    width: max-content;
  }

.scroll-container{
    z-index: 10;
    position: fixed;
}

h1.visible,
.page-two-title.visible,
.about-me-container.visible,
.projects-container.visible{
    opacity: 1 !important;
    transition: opacity .35s;
}

.ul-mobile > li > button{
    text-shadow: none;
    /* color: var(--background-gray); */
    font-weight: 600;
    color:var(--text-white)

}

.has-linear-gradient,
h1.current{
    transition: color 4s ease-in;
}

.header-bar{
    position: fixed;
    width: 100vw;
    top:0;
    padding: 1rem;
    display: flex;
    z-index: 22;
    border-bottom: .1rem solid var(--border-thin-white);
    background-color: rgb(40, 40, 40);
    flex-direction: column;
    animation: menu-drop .25s ease-in-out none 1;   
    color: var(--text-white);
}

.menu-icon{
    z-index: 100 !important;
}


@keyframes menu-drop{
    0%{
        transform: translateY(-200%);
    }

    100%{
        transform: translateY(0);
    }
}

.header-bar > a > button {
    background-image: none;
    background-color: transparent;
    color: #667281;
    padding-left: 2rem;
}

p{
    font-size: .7rem;
    color: var(--text-white);
    max-height: fit-content;
}

.nav-button-container{
    display: flex;
    justify-content: space-between;
    width: 100vw;
    list-style: none;
}
.nav-button{
    background-color: transparent;
    border: none;
    padding: .6rem;
    color: white;
}

.home-page-container{
    max-width: 100vw;
    overflow-x: hidden;
}

.home-scroll-btn{
    animation: alert-scroll 2s ease-in-out none infinite;
    opacity: 1;
}

@keyframes alert-scroll{
    0%{
        opacity: 1;
    }

    50%{
        opacity: .2;
    }

    100%{
        opacity: 1;
    }
}

.fixed-container{
    position: fixed;
    display: grid;
    z-index: 1;
    width: 100%;
    height: 100vh;
    /* background-image: linear-gradient(122deg, rgb(0 137 255), #1e82ff, rgb(0 135 251)); */
    justify-content: center;
    align-items: center;
}

.grid-page{
    height: 100vh;
    z-index: 2;
    position: relative;
    background-color: #ffffff;
    /* overflow: scroll; */
    overflow-y: hidden;
    overflow-x: hidden;
}

hr{
    width: 100%;
    border: none;
    height: 1px;
    background-color: var(--page-blue);
    margin-top: .2rem;
    margin-bottom: 1rem;
}

h1{
    /* font-size: 1.8rem;
    padding: 1rem; */
    /* background-image: var(--background-green);
    background-clip: text; */
    /* color: transparent; */
    padding-bottom: 1rem;
    color: var(--text-white);
}

h2{
    color: var(--subtitle-gray);
    padding: 1rem 2rem;
    font-size: 1.2rem;
}

li{
    list-style: none;

    /* background-image: var(--background-green);
    -webkit-text-fill-color:transparent;
    -webkit-background-clip: text; */
}

li > button {
    background-image: none;
    background-color: transparent;
    text-shadow: 0 1px 1px black;
    font-weight: 500;
    font-size: .9em;
    position: relative;
}




.contact-me-title{
    color: rgba(255,255,255,.5);
}

h3{
    color: rgb(122, 122, 122);
    font-size: .8rem;
    font-weight: 400;
}

.page-three-container{
    height: 100%;
    padding-top: 2rem;
    display: grid;
    align-items: center;
}

.projects-container{
    width: 100%;
    max-height: 100vh;
    transition: opacity .35s;
    position: relative;
    opacity: 0;
}

.project-card{
    display: grid;
    border-radius: .5rem;
    max-width: 100vw;
    gap: 1rem;
}

.projects-page-display{
    /* background-color: white; */
    display: flex;
    justify-content: center;
    max-width: 100vw;
    gap: 1rem;
}

.page-circle{
    width: .6rem;
    height: .6rem;
    background-color: #c9c9c9;
    border-radius: 900rem;
    transition: all .3s;

}

.currentPage{
    background-image: var(--background-green);
    width: .8rem;
    height: .8rem;
    border: unset .1rem solid rgb(255, 255, 255);
    transition: all .3s;
    
} 


.inView{
    border: 2px solid red;
}


.footer{
    border: 2px solid blue;
}


.project-container{
    min-width: 100vw;
    padding: 1rem;
    /* padding-top: 2rem; */
    display:grid;
    justify-content:center;
    justify-items: center;
    grid-template-rows: 1.5fr 4.5fr;
    height: 100vh;
}

.project-image-wrapper{
    /* border: 2px solid red; */
    width: 100%;
    position: relative;
    border-radius: .5rem;
    box-shadow:0 1px 20px 5px rgb(196, 196, 196);
    overflow: hidden;
    display: flex;
    justify-content: center;
    background-color: black;
}

.project-image-wrapper > img{
    position: relative;
    /* width: 100%; */
    height: 100%;
}

.skills-page{
    /* padding: 2rem 1rem 2rem; */
    display: flex;
    height: 100vh;
    overflow-y: hidden;
    /* width: 100vw; */
    overflow-x: hidden;
    /* background-color: rgb(31, 129, 241); */
    background-image: linear-gradient(122deg, rgb(0 137 255), #1e82ff, rgb(0 135 251));
    transition: transform .4s;

}

.focuses-container{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    justify-content: center;
    justify-self: center;
    justify-items: center;
    gap: .5rem;
    width: 100vw;
    padding: 1rem;


    
}

.skill-wrapper{
    justify-self: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: rgba(255, 255, 255, 0.6);
    font-weight: 500;
    /* font-size: 1.4rem; */
    text-align: center;
    gap: .3rem;
}

.skills-container{
    display: grid;
    width: 100vw;
    grid-template-columns: repeat(3, 1fr);
    padding: 2rem;
    align-self: center;
    gap: 1.2rem;
}

.home-text{
    animation: slide-in .5s cubic-bezier(0.175, 0.885, 0.32, 1.275) 1 forwards ;
}

@keyframes slide-in{
    from {
        transform: translateY(-1000px);
    }

    to{
        transform: translateY(0);
    }
}



/* .focus-section{
    justify-content: center;
    justify-items: center;
    padding: .5rem;
    text-align: center;
    color: white;
    background-color: rgba(255, 255, 255, 0.164);
    border-radius: .5rem;

} */

.focus-section-icon{
    /* color: rgb(255, 230, 0); */
    color: rgba(0, 0, 0, 0.247);
}

.focus-section > h3{
    color: rgba(0, 0, 0, 0.363);
    font-weight: 600;
    padding: .5rem;
}

.form-page{
    background: rgb(255, 255, 255);
}

.contact-form{
    /* border: 2px solid red; */
    display: grid;
    gap: .15rem;
    height: 100%;

}

.contact-form > div > input {
    border: none;
    outline: none;
    appearance: none;
    padding: .7rem;
    width: 100%;
    border-radius: .15rem;
    background-color: #8f8f8f;
    caret-color: rgb(150, 150, 150);
    color:var(--text-white);
    /* font-size: .7em; */
}

::placeholder{
    color: #777777;
    font-size: 1em;
}

textarea{
    color:var(--text-white);
    width: 100%;
    min-height: 20vh;
    /* min-height: 100%; */
    /* min-height: 8em; */
    /* height: 100%; */
    caret-color: rgb(150, 150, 150);
    border-radius: .15rem;
    padding: .7rem;
    border: none;
    outline: none;
    appearance: none;
    background-color: #8f8f8f;
    resize: none;
}

button{
    padding: .8em;
    border-radius: .3rem;
    border: none;
    background-image: var(--background-green);
    color: #ffffff;
    font-weight: 600;
}



.contact-me{
    padding: 1rem;
    color: rgb(172, 172, 172);
    align-self: center;
}

.contact-me-page{
    width: 100vw;
    height: 100vh;
}

.contact-content{
    display: grid;
    /* grid-template-columns: 1fr 1fr; */
    height: 100vh;
    /* border:2px solid red; */
    align-items: center;
}

.contact-form > div > input,
.contact-form > div >textarea{
    background-color: #454545bf !important;
}




.contact-content-container{
    display: grid;
    /* gap: 1rem; */
    height: 100%;
    width: 100%;
    padding: .5rem !important;
    gap: 1rem
}

.content-left{
    display: grid;
    /* align-items: stretch; */
    /* align-items: center; */
}

.contact-left > .contact-me {
    max-width: 50%;
    /* padding: 2rem; */
}


.contact-left > .contact-me > h1 {
    font-size: 4rem;
    display: none;
    /* position: absolute;
    top: 0; */
}


.contact-left{
    display: grid;
    align-items: center;
    /* border: 2px solid red; */
    width: 100vw;
    height: 100%;
}

.images-wrapper{
    overflow: hidden;
    display: flex;
    transition: transform .4s;

}

.images-wrapper > div {
    /* border: 3px solid red; */
    min-width: 100vw;
    position: relative;
    align-self: center;
    display: flex;
    justify-content: center;
}

.images-wrapper > div > img {
    max-width: 100vw;
    height: auto;
    position: relative;
    border: .6rem solid rgba(0, 0, 0, 0.26);
    border-radius: .4rem;
    max-height: 50vh;
}


.project-page{
    align-items: center;
    /* display: grid; */
    /* background-image: var(--background-green); */
}


.home-header{
    transition: filter .65s;
}

.loader {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    position: relative;
    animation: rotate 1s linear infinite
  }
  .loader::before , .loader::after {
    content: "";
    box-sizing: border-box;
    position: absolute;
    inset: 0px;
    border-radius: 50%;
    border: 5px solid #FFF;
    animation: prixClipFix 2s linear infinite ;
  }
  .loader::after{
    border-color: #98ee51;
    animation: prixClipFix 2s linear infinite , rotate 0.5s linear infinite reverse;
    inset: 6px;
  }

  .send-button{
    height: 100%;
    padding: .6rem;
    border: none;
    outline: none;
    font-weight: 800;
    font-size: .68rem;
    color: var(--background-gray);
  }
  
  a:visited{
    color:white
  }


  

  @keyframes rotate {
    0%   {transform: rotate(0deg)}
    100%   {transform: rotate(360deg)}
  }

  @keyframes prixClipFix {
      0%   {clip-path:polygon(50% 50%,0 0,0 0,0 0,0 0,0 0)}
      25%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 0,100% 0,100% 0)}
      50%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,100% 100%,100% 100%)}
      75%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,0 100%,0 100%)}
      100% {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,0 100%,0 0)}
  }


.three-canvas-container{
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    position: relative;
}

.page-three-title::before{
    content: 'WHO AM I';
    position: absolute;
    right: 1rem;
    color: white;
    opacity: .032;
    font-size: 6rem;
    top: -2rem;
}

.email-text{
    font-size: 1rem !important;
}

.symbol-green{
    background-image: var(--background-green);
    -webkit-background-clip: text;
     -webkit-text-fill-color: transparent;
    color: transparent
}

/* *{
    scrollbar-width: none;
} */

::-webkit-scrollbar{
    /* display:none; */
    background-color: rgb(49, 49, 49);
    width: .5rem;
}


::-webkit-scrollbar-thumb{
    background-image: var(--background-green);
    border-radius: 900rem;
    width: .5rem;
}

.not-found-container{
    height: 100vh;
    display: grid;
}

.contact-title{
    position: relative;
    top:0;
}





@keyframes opacity-fade-menu{
    from{
        opacity: 0;
    }

    to{
        opacity: 1;
    }
}



@media(orientation: landscape){
    :root{
        font-size: 1.4vw;
    }

    .focuses-container{
        grid-template-columns: repeat(4,1fr);
    }


    .projects-container{
        display: grid;
        grid-template-columns: repeat(2, 1fr) !important; 
        height: 100%;
        gap:1rem;
        position: relative;
    }

    button.current::after{
        content: '';
        position: absolute;
        width: 100%;
        height: 8%;
        left: 50%;
        transform: translateX(-50%);
        top:90%;
        background-image: var(--background-green);
        animation: opacity-fade-menu .12s ease-in;
        border-radius: 900rem;
        box-shadow: 0px 0px 20px .7px black;
    }

}

@media(orientation: portrait) {
    :root{
        font-size: 1.05rem;
    }

    .projects-page-projects{
        grid-template-columns: repeat(1, 1fr) !important;
    }

    .projects-page-container{
        padding: 1rem !important;
        gap: 1rem;
    }

    /* h1{
        padding-left: 1.3rem!important;
    } */

    .about-me-container{
        grid-template-columns: 1fr !important;
        grid-template-rows: 1fr 1fr !important;
    }

    .scroll-page-container{
        padding: 0 !important;
    }

    .projects-container{
        transition: all .4s cubic-bezier(0.175, 0.885, 0.32, 1.275) !important;
    }

    .contact-me{
        align-self: flex-start;
    }

    .contact-left > .contact-me {
        max-width: 100%;
        padding: 1rem !important;
        /* padding: 2rem; */
        /* height: 100%; */
    }

    .contact-left > .contact-me > .contact-content-container > .contact-form > div > input,
    .contact-left > .contact-me > .contact-content-container > .contact-form > div >textarea{
        background-color: #454545bf !important;
    }

    .contact-title{
        padding-left: 1rem !important;
        font-size: 3rem !important;
    }


    .page-five{
        width: 100vw !important;
    }

    button.current::after{
    content: '';
    position: absolute;
    width: .7em;
    height: .7em;
    top: 50%;
    transform: translateY(-50%);
    right: -.4em;
    /* left: 50%; */
    /* transform: translateX(-50%); */
    /* top:90%; */
    background-image: var(--background-green);
    animation: opacity-fade-menu .12s ease-in;
    border-radius: 900rem;
    box-shadow: 0px 0px 20px .7px black;
}

}

@media((orientation:landscape ) and (aspect-ratio < 2.17)){
    :root{
        font-size: 1.2vw;
    }

    p{
        font-size: .85em !important;
    }

    li > button {
        font-size: .9rem;
    }
}
